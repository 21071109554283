import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

const PledgeToVote = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main center-text">
        <ReactMarkdown children={data.markdownRemark.frontmatter.description} />
        <iframe
          src="https://app.msvotes.civicengine.com/w/address/"
          title={data.markdownRemark.frontmatter.heading}
          style={{ background: 'transparent', border: 'none', height: '150px', width: '100%' }}
        ></iframe>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query PledgeToVoteQuery {
  markdownRemark(frontmatter: {title: {eq: "Pledge to Vote"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      description
    }
  }
}
`

export default PledgeToVote